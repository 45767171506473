import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { FormControl, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
@Component({
  selector: 'app-save-file-firestorage',
  templateUrl: './save-file-firestorage.component.html',
  styleUrls: ['../../../../assets/css/adminLteStyle.scss','./save-file-firestorage.component.scss'],
  providers: [
	  DatePipe
  ]
})
export class SaveFileFirestorageComponent implements OnInit {
  public onClose: Subject<Boolean>;
	data: any;
	ref: AngularFireStorageReference;
	task: AngularFireUploadTask;
	isSave: boolean = false;
	fileArchivoUrl: string = null;
	formControlFile : FormControl = new FormControl(null,Validators.required);
	disable_btn=false;
	constructor(
		public bsModalRef: BsModalRef,
		public toastr: ToastrManager,
		private datePipe: DatePipe,
		private storage: AngularFireStorage,
		private spinner: NgxSpinnerService
	) { }

	ngOnInit() {
		this.onClose = new Subject();
	}
	file:File=null;
	imageSrc=null;
	onFileAdded(event) {
		if (event.target.files.length > 0  && event.target.files[0].size<= 80000000 && event.target.files[0].size!= undefined ) {
			const files: { [key: string]: File } = event.target.files;
			for (let key in files) {
				if (!isNaN(parseInt(key))) {
					this.file = files[key];
				}
			}
		} 
		else {
			if (event.target.files[0].size > 80000000){
				this.toastr.warningToastr('El tamaño excede a los 8Mb de capacidad. No se podra guardar', 'Advertencia!', {
					toastTimeout: 2000,
					showCloseButton: true,
					animate: 'fade',
					progressBar: true
				});
			}
			this.file = null;
			this.imageSrc=null;
		}
		if (this.file!=null){
			this.formControlFile.setValue(this.file.name);
		}
	}

  	saveFile() {
		if(this.file==null || this.formControlFile.value==null){
			this.toastr.warningToastr('Debe ingresar un documento.', 'Advertencia!', {
			  toastTimeout: 2000,
			  showCloseButton: true,
			  animate: 'fade',
			  progressBar: true
			});
		}else{
			this.spinner.show();
			this.disable_btn=true;
			let nombreArchivo = this.file.name;
			let datoArchivo = nombreArchivo.split(".");
			const filePath = this.data.carpeta+'/'+this.data.sub_carpeta+'/'+ this.data.documento+'_'+ Date.now() + '.' + datoArchivo[datoArchivo.length - 1];
			this.ref = this.storage.ref(filePath);
			this.task = this.ref.put(this.file);
			this.task.snapshotChanges().pipe(
				finalize(() => {
					this.ref.getDownloadURL().subscribe(url => {
						this.closeModal(url);
						this.spinner.hide();
					});
				})
			).subscribe();
			//this.formControlFile.setValue(null);
		}
	}

	closeModal(e=null) {
		this.onClose.next(e);
		this.bsModalRef.hide();
	}


}
