import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { HeaderBasicAuthorizationService } from '../../../../_shared/services/header-basic-authorization.service';
import { ApiEnum } from '../../../../_shared/enums/api.enum';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FrontService {

  constructor(
    private http: HttpClient,
    private headerBasicAuthorization: HeaderBasicAuthorizationService
  ) { }

  public getContenidoWeb() {
		return this.http.get(`${environment.api.WS_WEB.url}/Landing/GetContenidos`,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_WEB) })
	.pipe(map(data => data));
  }

  public getActores() {
		return this.http.get(`${environment.api.WS_WEB.url}/Landing/GetActores`,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_WEB) })
	.pipe(map(data => data));
  }

  public getActoresByTipo() {
		return this.http.get(`${environment.api.WS_WEB.url}/Landing/GetActoresPorTipo`,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_WEB) })
	.pipe(map(data => data));
  }

  public saveDataFormulario(model) {
		return this.http.post(`${environment.api.WS_WEB.url}/DataFormulario`, model,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_WEB) })
	.pipe(map(data => data));
  }

  public GetConfiguracion() {
		return this.http.get(`${environment.api.WS_WEB.url}/Landing/GetConfiguracion`,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_WEB) })
	.pipe(map(data => data));
  }

  public getDataIndicador(Contenido) {
		return this.http.get(`${environment.api.WS_WEB.url}/Landing/GetIndicadorPorContenido?IdContenido=${Contenido}`,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_WEB) })
	.pipe(map(data => data));
  }

  public ListarMultivalor(Tabla) {
	   return this.http.get(`${environment.api.WS_IT.url}/Multivalor/Listar?prmstrTabla=${Tabla}`,
	   { headers: this.headerBasicAuthorization.get(ApiEnum.WS_IT) })
    .pipe(map(data => data));
  }

  public RegistrarUsuario(model) {
	   return this.http.post(`${environment.api.WS_IT.url}/Seguridad/RegistroUsuarioLogin`, model,
	   { headers: this.headerBasicAuthorization.get(ApiEnum.WS_IT) })
    .pipe(map(data => data));
  }
  public GetRoles() {
		return this.http.get(`${environment.api.WS_IT.url}/Rol/GetRoles`,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_IT) })
	.pipe(map(data => data));
  }

  public ListarBeneficios() {
		return this.http.get(`${environment.api.WS_WEB.url}/Beneficio/WEB_ListarBeneficios`,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_WEB) })
	.pipe(map(data => data));
  }
 
  public ListarSolicitudesVigentes(dep:string) {
		return this.http.get(`${environment.api.WS_WEB.url}/Requerimiento/WEB_ListarSolicitudesVigentes?Departamento=${dep}`,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_WEB) })
	.pipe(map(data => data));
  }

  public GetDepartamentosCombo() {
	return this.http.get(`${environment.api.WS_WEB.url}/Requerimiento/GetDepartamentosCbo`,
	{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_WEB) })
	.pipe(map(data => data));
 }

}