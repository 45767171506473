import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../_core/services/auth.service';
//import { PermissionNavigationService } from '../../services/permission-navigation.service';
import { Navigation } from '../../_core/interfaces/navigation';
import { PermissionNavigationService } from 'app/_shared/services/permission-navigation.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuardService implements CanActivate {

  constructor(private authService: AuthService,
    private permissionNavigationService: PermissionNavigationService){
              //private permissionNavigationService: PermissionNavigationService) {
  }


  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let stateUrl = state.url.split('?')[0];
    if (!this.authService.isLoggedIn()) {
      this.authService.redirectToHome();
      return false;
    } else {
      if(this.permissionNavigationService.get() == null) {
        return this.authService.findPermissions()
          .pipe(map((response: any) => {
            let navigation: Navigation = response.Data as Navigation;
            this.permissionNavigationService.set(navigation, []);
            let urls = this.permissionNavigationService.getUrls();
            this.permissionNavigationService.loadViewAction(stateUrl);
            if (urls.indexOf(stateUrl) >= 0) {
              return true;
            } else {
              //this.authService.redirectToNotAllowed();
              return false;
            }
        }));
      } else {
        let urls = this.permissionNavigationService.getUrls();
        if(this.permissionNavigationService.get() != null) {
					this.permissionNavigationService.loadViewAction(stateUrl);
				}
        if (urls.indexOf(stateUrl) >= 0) {
          return true;
        } else {
          this.authService.redirectToNotAllowed();
          return false;
        }
      }
      return true;
    }
  }

}